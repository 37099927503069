<template>
  <v-row align='center' justify='center' class='text-center'>
    <v-btn class='ml-4'
      ><download-excel
        class='btn btn-default'
        :data='list1'
        :fields='json_fields'
        worksheet='My Worksheet'
        name='ข้อมูลสรุปรายงานการเเจ้งเหตุ.xls'
        header='รายงานการเเจ้งเหตุ'
      >
        <img src='@/assets/Excel-Logo.png' width='50px' />
        Download รายงานการเเจ้งเหตุ
      </download-excel></v-btn
    >
  </v-row>
</template>
<script>
export default {
  props: ['list1'],
  data() {
    return {
      json_fields: {
        'หมายเลขการเรียกใช้บริการ': 'requestNumber',
        // 'หมายเลขการเรียกใช้บริการ': {
        //   field: 'requestNumber',
        //   callback: (value) => {
        //     return value.toString();
        //   }
        // },
        'สถานะ': 'status',
        'วัน เวลา ที่เรียกใช้บริการ': 'createdAt',
        'วัน เวลา ที่เจ้าหน้าที่รับเริ่อง': 'updatedAt',
        'ประเภทการเรียกใช้บริการ': 'serviceTypeName',
        'ผู้เรียกใช้บริการ': 'userReqName',
        'อีเมลล์ผู้เรียกใช้บริการ': 'userReqEmail',
        'เบอร์โทรศัพท์ผู้เรียกใช้บริการ': 'userReqTel',
        'รายละเอียดการเรียกใช้บริการ': 'description',
        'เจ้าหน้าที่ ที่รับงาน': 'servicerName',
        'เบอร์โทรศัพท์เจ้าหน้าที่ที่รับงาน': 'servicerTel',
        'อีเมลล์เจ้าหน้าที่ที่รับงาน': 'servicerEmail'
      },
      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8'
          }
        ]
      ],
      list1: []
    }
  },
  async created() {
    // this.getREportXray();
  },
  methods: {
    // async getREportXray() {
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/form/xrayReport`
    //   );
    //   console.log('data', response.data.data[0]);
    //   this.list1 = response.data.data[0];
    // },
  }
}
</script>
